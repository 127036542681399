class Config {
  AUTH_API_URL = 'https://auth.gen-t.science';

  API_URL = 'https://gapi.stag.gen-t.science';

  SYSTEM_ID = '1';

  SYSTEM_NAME = 'samples';

  AES_KEY = 'EkA85bUJ5QyBIEyv FacDImw2C4Qfb0Mu';

  SESSION = 'gen-t.samples.auth';
}

export default new Config();
