/* eslint-disable no-return-await */
import Config from '@/config/config';
import AuthService from '@/services/auth';

const ApiUrl = Config.API_URL;
const GraphQLSufix = '/v1/graphql';

async function GetUserToken() {
  const userInSession = await AuthService.getAuthData();
  return userInSession.Token;
}

async function fetchGraphQL(operationsDoc, operationName, variables) {
  const TokenData = await GetUserToken();
  const result = await fetch(`${ApiUrl}${GraphQLSufix}`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${TokenData}`,
      'Content-Type': 'application/json',
      'x-hasura-role': 'admin',
    }),
    body: JSON.stringify({
      query: operationsDoc,
      variables,
      operationName,
    }),
  });

  return await result.json();
}

const operationsSamples = `
  query MyQuery {
    patient {
      samples {
        updated_at
        temperature_on_receipt_min
        temperature_on_receipt_max
        agb_id
        biological_sample_processed_at
        collected_at
        created_at
        deleted_at
        form_completed_at
        gent_id
        had_immediate_processing
        has_data_logger
        id
        temperature_on_receipt_current
        temperature_on_rec_center_min
        temperature_on_rec_center_max
        temperature_on_rec_center_current
        sample_type
        responsible_professional
        patient_id
        receipt_at
        original_tube_code
        observations
        is_suitable
      }
      name
      cpf
      id
    }
  }
`;

function fetchMyQuery() {
  // TODO: Constructor about operationsSamples?
  return fetchGraphQL(operationsSamples, 'MyQuery', {});
}

async function startFetchMyQuery() {
  const { errors, data } = await fetchMyQuery();

  if (errors) return { error: errors };
  if (data.patient.length === 0) return { error: [{ message: 'Listagem vazia.' }] };

  return { data: data.patient };
}

export default startFetchMyQuery;
