/* eslint-disable no-return-await */
import Config from '@/config/config';
import AuthService from '@/services/auth';

const ApiUrl = Config.API_URL;
const GraphQLSufix = '/v1/graphql';

async function GetUserToken() {
  const userInSession = await AuthService.getAuthData();
  return userInSession.Token;
}

async function fetchGraphQL(operationsDoc, operationName, variables) {
  const TokenData = await GetUserToken();
  const result = await fetch(`${ApiUrl}${GraphQLSufix}`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${TokenData}`,
      'Content-Type': 'application/json',
      'x-hasura-role': 'admin',
    }),
    body: JSON.stringify({
      query: operationsDoc,
      variables,
      operationName,
    }),
  });
  return await result.json();
}

// biological_sample_processed_at: ${data.biological_sample_processed_at != null ? ${data.biological_sample_processed_at} : null}
function operationsSamplesConstructor(data) {
  return `mutation SaveSample {
          sample: insert_sample(
            objects: {
              gent_id: "${data.gent_id}"
              patient_id: "${data.patient_id}"
              biological_sample_processed_at: ${data.biological_sample_processed_at != null ? `"${data.biological_sample_processed_at}"` : null}
              collected_at: ${data.collected_at != null ? `"${data.collected_at}"` : null}
              form_completed_at: "now()"
              had_immediate_processing: ${data.had_immediate_processing}
              has_data_logger: ${data.has_data_logger}
              is_suitable: ${data.is_suitable}
              observations: "${data.observations}"
              original_tube_code: "${data.original_tube_code}"
              receipt_at: "${data.receipt_at}"
              responsible_professional: "${data.responsible_professional}"
              unity_id: "${data.unity_id}"
              sample_type: "${data.sample_type}"
              temperature_on_rec_center_current: ${data.temperature_on_rec_center_current}
              temperature_on_rec_center_max: ${data.temperature_on_rec_center_max}
              temperature_on_rec_center_min: ${data.temperature_on_rec_center_min}
              temperature_on_receipt_current: ${data.temperature_on_receipt_current}
              temperature_on_receipt_max: ${data.temperature_on_receipt_max}
              temperature_on_receipt_min: ${data.temperature_on_receipt_min}
            }
          ) {
            returning {
              gent_id
              created_at
            }
          }
          
          update_patient_by_pk(
            pk_columns: { id: "${data.patient_id}" }
            _set:{
              birth_date_samples: ${data.birth_date_samples != null ? `"${data.birth_date_samples}"` : null}
            }
          ) {
            id
          }

        }`;
}

function fetchMyQuery(newSampleData) {
  const operationsSamples = operationsSamplesConstructor(newSampleData);
  return fetchGraphQL(operationsSamples, 'SaveSample', {});
}

async function newSampleFetch(newSampleData) {
  const { errors, data } = await fetchMyQuery(newSampleData);

  if (errors) return { error: errors };
  return { data: data.sample.returning };
}

export default newSampleFetch;
