<!-- eslint-disable vue/require-v-for-key -->
<!-- eslint-disable vue/no-parsing-error -->
<!-- eslint-disable vue/valid-v-slot -->
<!-- eslint-disable new-cap -->

<!-- TODO
  - Adicionar mascaras e validações - https://getbootstrap.com/docs/5.0/forms/validation/
  - Criar component de perfil do participante padrão
  - "Componentização" dos alertas 
-->

<template>
  <div class="container samples-container">
    <nav class="navbar navbar-light  fixed-top  navbar-light bg-light">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">
          <img src="../assets/gen-t-icon.png" alt="" width="28" height="28" class="d-inline-block align-text-top">
          &nbsp;&nbsp;Sistema Interno
        </a>

        <!-- <form class="d-flex">
          <input class="form-control" type="search" placeholder="Pesquisar" aria-label="Pesquisar" disabled>
          <button class="btn btn-outline-primary" type="submit" disabled>Buscar</button>
        </form> -->
        <form class="d-flex">
          <button class="btn btn-sm btn-outline-dark" @click="logout()">Sair</button>
        </form>
      </div>
    </nav>

    <!-- As a link -->
    <nav class="navbar navbar-light bg-light">
      <div class="container-fluid">
        [Sistema interno] Participantes + Amostras
      </div>
    </nav>

    <hr />

    <nav class="navbar navbar-light bg-light">
      <form class="container-fluid justify-content-start">
        <button class="btn btn-sm btn-warning btn-line" type="button" @click="filter('no-samples')"
          v-if="filterFlag.length == 0">
          Partic. <strong>Sem</strong> Amostra
        </button>
        <button class="btn btn-sm btn-primary btn-line" type="button" @click="filter('with-samples')"
          v-if="filterFlag.length == 0">
          Partic. <strong>Com</strong> Amostra
        </button>
        <button class="btn btn-sm btn-secundary btn-line" type="button" @click="getSamples()">
          Limpar Filtro
        </button>
      </form>
    </nav>

    <hr v-if="success" />

    <div class="col" v-if="success">
      <div class="col">
        <div class="alert alert-success" role="alert">{{successMessage}}</div>
      </div>
    </div>

    <hr v-if="error" />

    <div class="col" v-if="error">
      <div class="col">
        <div class="alert alert-danger" role="alert">{{errorMessage}}</div>
      </div>
    </div>

    <hr />

    <div class="col">
      <!--  <div class="btn-group" role="group" aria-label="Basic example">
        <button class="btn btn-primary" v-if="showSimpleOrAll" label="Dados das Amostras" icon="eye-slash"
          variant="primary" @click="showSimpleOrAll = !showSimpleOrAll" disabled>
          [-] Dados das amostras
        </button>
        <button class="btn btn-secundary" v-if="!showSimpleOrAll" label='Dados das Amostras' icon="eye"
          variant="primary" @click="showSimpleOrAll = !showSimpleOrAll" disabled>
          [+] Dados das amostras
        </button>
        <button class="btn btn-secundary btn-disabled" squared variant="outline-primary" @click="updateRowTest()"
          disabled>
          Atualizar Linha [teste]
        </button>
        <button class="btn btn-secundary btn-disabled" squared variant="outline-primary" disabled>
          Messages
        </button>
      </div>-->
    </div>

    <div class="col">
      <div class="container" style="margin-top: 2em">
        <div class="row">
          <div class="col">
            <table id="tableComponent" class="table table-condensed table-striped">
              <thead>
                <tr>
                  <th v-for="item in participantsFields" :key='item' @click="setParticipant(item)">
                    {{item}}
                  </th>
                  <th>
                    Amostra
                  </th>
                  <th>
                    action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="tr" v-for="item in participants" :key='item' @click="setParticipant(item)">
                  <td v-for="field in participantsFields" :key='field'>
                    {{item[field]}}
                  </td>
                  <td>
                    <div v-if="item.samples.length == 0">
                      <span class="badge bg-warning text-dark">Sem amostra</span>
                    </div>
                    <div v-else>
                      <span class="badge bg-primary text-light">Amostra cadastrada</span>
                    </div>
                  </td>
                  <td>
                    <div class="d-grid gap-2 d-md-flex justify-content-md-center">
                      <button class="btn btn-primary btn-sm"
                        v-if="participantInDetail == item && item.samples.length == 0" data-bs-toggle="modal"
                        data-bs-target="#modal-sample-manual-registration">
                        +
                      </button>
                      <button class="btn btn-primary btn-sm" v-else-if="participantInDetail == item"
                        data-bs-toggle="modal" data-bs-target="#modal-participant">
                        +
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" ref="modalParticipant" id="modal-participant" tabindex="-1"
      aria-labelledby="modal-sample-manual-registration-label" data-bs-backdrop="static" data-bs-keyboard="false"
      aria-hidden="true">
      <div class="modal-dialog modal-xl" v-if="participantInDetail != null">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Participante {{ participantInDetail.name }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <!-- TODO: criar component de perfil do participante padrão -->
            <div class="row">
              <div class="col">
                <p>Nome: <strong>{{ participantInDetail.name }}</strong></p>
              </div>
              <!-- <div class="col">
                <p>CPF: <strong>{{ participantInDetail.cpf }}</strong></p>
              </div> -->
              <!-- <div class="col">
                <p>ID: <strong>{{ participantInDetail.id }}</strong></p>
                <p>GENTID: <strong>{{ participantInDetail.gentId }}</strong></p>
              </div> -->
            </div>

            <!-- <participant-identifier :name="participant.name" ></participant-identifier> -->

            <hr />

            <div class="col" v-if="participantInDetail.samples.length == 0">
              <!-- TODO: "Componentização" dos alertas -->
              <div class="alert alert-warning" role="alert">
                <h5 class="alert-heading">Amostra não cadastrada! </h5>
                <a href="#" class="alert-link" @click="newSampleModalClick(participantInDetail)">Clique aqui</a> caso
                tenha os dados da amostra deste participante e faça o cadastro da amostra.
              </div>
            </div>
            <div class="col" v-else>
              <div class="alert alert-success" role="alert">
                <h5 class="alert-heading">Amostras cadastradas [{{participantInDetail.samples.length}}]</h5>
                Esse participante já tem sua amostra vinculada ao seu perfil.
              </div>

              <div class="row">
                <div class="col">
                  <p><strong>{{`AGB${String(participantInDetail.samples[0].agb_id).padStart(6, "0")}`}}</strong></p>
                </div>
                <div class="col">
                  <p>Tipo: <strong>{{ participantInDetail.samples[0].sample_type }}</strong></p>
                </div>
                <div class="col">
                  <p>Adequada: <strong>{{ participantInDetail.samples[0].is_suitable }}</strong></p>
                </div>
              </div>

              <hr />

              <div class="alert alert-success" role="alert">Cógido de barra configurado
                [{{participantInDetail.samples[0].original_tube_code}}]</div>

              <div class="alert alert-warning" role="alert">
                <h5 class="alert-heading">Etiqueta ainda não impressa! </h5>
                <a href="#" class="alert-link" @click="etiqueta()">
                  Clique aqui</a> Para gerar o arquivo para impressão da sua etiqueta.
              </div>

            </div>
          </div>
          <div class="modal-footer">
            <button id="closeModalParticipant" type="button" class="btn btn-secondary"
              data-bs-dismiss="modal">Fechar</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <button class="visually-hidden" style="" name="SampleManualRegistrationName" data-bs-toggle="modal"
      data-bs-target="#modal-sample-manual-registration">
      Ocultar
    </button>
    <div class="modal fade" id="modal-sample-manual-registration" tabindex="-1" data-bs-backdrop="static"
      data-bs-keyboard="false" aria-labelledby="modal-sample-manual-registration-label" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <!-- TODO: adicionar if do participante ok -->
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Cadastro de amostra manual</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body" v-if="participantInDetail != null">

            <div class="col" v-if="success">
              <div class="col">
                <div class="alert alert-success" role="alert">{{successMessage}}</div>
              </div>
            </div>

            <div class="col" v-if="error">
              <div class="col">
                <div class="alert alert-danger" role="alert">{{errorMessage}}</div>
              </div>
            </div>

            <form id="sample-manual-registration-form" @submit.stop.prevent class="login-area">

              <!-- TODO: Criar template para destaque do particiapnte -->
              <div class="row">
                <div class="col">
                  <p>Nome: <strong>{{ participantInDetail.name }}</strong></p>
                </div>
                <!-- <div class="col">
                  <p>CPF: <strong>{{ participantInDetail.cpf }}</strong></p>
                </div> -->
                <!-- <div class="col">
                  <p>ID: <strong>{{ participantInDetail.id }}</strong></p>
                  <p>GENTID: <strong>{{ participantInDetail.gentId }}</strong></p>
                </div> -->
              </div>

              <!-- <div class="col">
                <label for="samples">Digite o CPF ou Dr.Consulta Id ou o gen-t Id do participante</label>
                <div class="input-group mb-3">
                  <div class="input-group-text">
                    CPF&nbsp;&nbsp;<input name="flexRadioIdentificador" id="flexRadioIdentificador" class="form-check-input mt-0" type="radio" value="cpf" aria-label="CPF do paciente" disabled>
                  </div>
                  <div class="input-group-text">
                    GENTID&nbsp;&nbsp;<input disabled name="flexRadioIdentificador" id="flexRadioIdentificador" class="form-check-input mt-1" type="radio" value="gentId" aria-label="Identificador gen-t do paciente" checked>
                  </div>
                  <div class="input-group-text">
                    DRCID&nbsp;&nbsp;<input name="flexRadioIdentificador" id="flexRadioIdentificador" class="form-check-input mt-2" type="radio" value="drcId" aria-label="Identificador do dr.consulta do paciente" disabled>
                  </div>
                  <input type="text" class="form-control" v-model="formData.patient_id" id="patient_id" disabled />
                  <button class="btn btn-primary" type="button" disabled>Buscar Paciente</button>
                </div>
              </div> -->

              <hr />

              <div class="row">
                <div class="col">
                  <label for="samples">Código de barras (tudo)</label>
                  <input class="form-control" type="text" v-model="formData.original_tube_code" id="original_tube_code"
                    placeholder="" inputmode="text" autocomplete="off">
                </div>
                <div class="col"></div>
                <div class="col"></div>
              </div>

              <!-- <hr />

              <div class="row">
                <div class="col">
                  <label for="samples">ID</label>
                  <input class="form-control" type="text" v-model="formData.patient_id" id="patient_id" placeholder=""
                    inputmode="text" autocomplete="off" disabled>
                </div>
                <div class="col">
                  <label for="samples">GEN-T-ID</label>
                  <input class="form-control" type="text" v-model="formData.gent_id" id="gent_id" placeholder=""
                    inputmode="text" autocomplete="off" disabled>
                </div>
                <div class="col">
                </div>
              </div> -->

              <hr />

              <div class="row">
                <div class="col">
                  <label for="samples">Data de nascimento:</label>
                  <Datepicker 
                    v-model="birth_date_samples" 
                    :format="'dd/MM/yyyy'" :previewFormat="'dd/MM/yyyy'"
                    :startDate="'01/01/1980'"
                  >
                  </Datepicker>
                  <!-- :locale="br" -->
                  <div class="form-text"></div>
                </div>
                <div class="col">
                  <label for="samples">Centro recrutador:</label>
                  <select class="form-select" id="unity_id" v-model="formData.unity_id">
                    <option :key="item" v-for="item in recruitingCenterOptions" :value="item.value">{{item.text}}
                    </option>
                  </select>
                  <div class="form-text"></div>
                </div>
              </div>

              <hr />

              <div class="row">
                <div class="col">
                  <h6>Horários</h6>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <label for="samples" class="small-3">Coleta:</label>
                  <Datepicker
                    v-model="collected_at"
                    :format="'dd/MM/yyyy  hh:mm'" :previewFormat="'dd/MM/yyyy hh:mm'"
                  >
                  </Datepicker>
                </div>
                <div class="col">
                  <label for="samples" class="small-3">Processamento:</label>
                  <Datepicker 
                    v-model="biological_sample_processed_at"
                    :format="'dd/MM/yyyy  hh:mm'" :previewFormat="'dd/MM/yyyy hh:mm'"
                  >
                  </Datepicker>
                </div>
                <div class="col">
                  <label for="samples" class="small-3"><span class="required"></span>Recepção no laboratório:</label>
                  <Datepicker 
                    v-model="receipt_at" value="receipt_at"
                    :format="'dd/MM/yyyy  hh:mm'" :previewFormat="'dd/MM/yyyy hh:mm'"
                  >
                  </Datepicker>
                </div>
              </div>

              <hr />

              <div class="row">
                <div class="col">
                  <h6>Qualidade</h6>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <label for="samples" class="small-4">Processamento Imediato?</label>
                  <select class="form-select" id="had_immediate_processing" v-model="formData.had_immediate_processing">
                    <option :key="item" v-for="item in hadImmediateProcessingOptions" :value="item.value">{{item.text}}
                    </option>
                  </select>

                </div>
                <div class="col">
                  <label for="samples" class="small-4">Tipo da amostra:</label>
                  <select class="form-select" id="had_immediate_processing" v-model="formData.sample_type">
                    <option :key="item" v-for="item in sampleTypeOptions" :value="item.value">{{item.text}}</option>
                  </select>
                </div>
                <div class="col">
                  <label for="samples" class="small-4">Adequada?</label>
                  <select class="form-select" id="is_suitable" v-model="formData.is_suitable">
                    <option :key="item" v-for="item in isSuitableOptions" :value="item.value">{{item.text}}</option>
                  </select>
                </div>
                <div class="col">
                  <label for="samples" class="small-4">Data Logger?</label>
                  <select class="form-select" id="has_data_logger" v-model="formData.has_data_logger">
                    <option :key="item" v-for="item in dataLoggerOptions" :value="item.value">{{item.text}}</option>
                  </select>
                </div>
              </div>

              <hr />

              <div class="row">
                <div class="col">
                  <h6>Temperaturas</h6>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <label for="samples" class="small-4">Centro (Min)</label>
                  <input class="form-control" type="number" v-model="formData.temperature_on_rec_center_min"
                    id="temperature_on_rec_center_min" placeholder="" inputmode="numeric" autocomplete="off">
                </div>
                <div class="col">
                  <label for="samples" class="small-4">Centro (Max)</label>
                  <input class="form-control" type="number" v-model="formData.temperature_on_rec_center_max"
                    id="temperature_on_rec_center_max" placeholder="" inputmode="numeric" autocomplete="off">
                </div>
                <div class="col">
                  <label for="samples" class="small-4">Centro (Atual)</label>
                  <input class="form-control" type="number" v-model="formData.temperature_on_rec_center_current"
                    id="temperature_on_rec_center_current" placeholder="" inputmode="numeric" autocomplete="off">
                </div>
                <div class="col"></div>

                <div class="col">
                  <label for="samples" class="small-4">Recepção (Min)</label>
                  <input class="form-control" type="number" v-model="formData.temperature_on_receipt_min"
                    id="temperature_on_receipt_min" placeholder="" inputmode="numeric" autocomplete="off">
                </div>
                <div class="col">
                  <label for="samples" class="small-4">Recepção (Max)</label>
                  <input class="form-control" type="number" v-model="formData.temperature_on_receipt_max"
                    id="temperature_on_receipt_max" placeholder="" inputmode="numeric" autocomplete="off">
                </div>
                <div class="col">
                  <label for="samples" class="small-4">Recepção (Atual)</label>
                  <input class="form-control" type="number" v-model="formData.temperature_on_receipt_current"
                    id="temperature_on_receipt_current" placeholder="" inputmode="numeric" autocomplete="off">
                </div>
              </div>

              <hr />

              <div class="row">
                <div class="col">
                  <label for="samples">Profissional responsável:</label>
                  <input class="form-control" type="text" v-model="formData.responsible_professional"
                    id="responsible_professional" placeholder="" inputmode="text" autocomplete="off">
                </div>
                <div class="col-lg-6">
                  <label for="samples">Observação</label>
                  <input class="form-control" type="text" v-model="formData.observations" id="observations"
                    placeholder="" inputmode="text" autocomplete="off">
                </div>
              </div>

              <hr />

            </form>
          </div>
          <div class="modal-body" v-else>
            <div class="alert alert-danger" role="alert">Erro ao carregar informações do participante. Tente novamente.
            </div>
          </div>

          <div class="modal-body" v-if="success">
            <div class="col">
              <div class="alert alert-success" role="alert">{{successMessage}}</div>
            </div>
          </div>

          <div class="modal-body" v-if="error">
            <div class="col">
              <div class="alert alert-danger" role="alert">{{errorMessage}}</div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
            <button type="button" class="btn btn-primary" @click="onSubmitSampleManualRegistration">
              Cadastrar Amostra
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import recruitingCenterFetchMyQuery from '../services/recruiting_center/fetch-query-graphql';
import samplesFetchMyQuery from '../services/fetch-query-graphql';
import newSampleFetch from '../services/samples/new';

export default {
  components: {
    Datepicker,
  },
  data() {
    return {
      filterFlag: '',
      error: false,
      errorMessage: '',
      success: false,
      successMessage: '',
      pagination: 1,
      last_agb_id: 'Carregando...',
      formload: false,
      birth_date_samples: null,
      receipt_at: null,
      collected_at: null,
      biological_sample_processed_at: null,
      hadImmediateProcessingOptions: [
        { value: true, text: 'Sim' },
        { value: false, text: 'Não' },
      ],
      isSuitableOptions: [
        { value: true, text: 'Sim' },
        { value: false, text: 'Não' },
      ],
      dataLoggerOptions: [
        { value: true, text: 'Sim' },
        { value: false, text: 'Não' },
      ],
      sampleTypeOptions: [
        { value: 'blood', text: 'Sangue' },
        { value: 'plasma', text: 'Plasma' },
      ],
      biologicalSexOptions: [
        { value: '-', text: 'Selecione um gênero' },
        { value: 'men', text: 'Masculino' },
        { value: 'women', text: 'Feminino' },
      ],
      recruitingCenterOptions: [
        { value: '-', text: 'Selecione o centro da amostra' },
      ],
      completedForm: false,
      showSimpleOrAll: true,
      participantInDetail: null,
      participantsFields: ['name', 'cpf'],
      participantsData: [],
      formData: {
        gent_id: crypto.randomUUID(),
        patient_id: '',
        agb_id: 0,
        biological_sample_processed_at: '',
        collected_at: '',
        created_at: '',
        deleted_at: '',
        form_completed_at: '',
        had_immediate_processing: true,
        has_data_logger: false,
        is_suitable: true,
        original_tube_code: '',
        observations: '',
        responsible_professional: '',
        receipt_at: '',
        sample_type: 'blood',
        unity_id: '-',
        temperature_on_receipt_min: '',
        temperature_on_receipt_max: '',
        temperature_on_receipt_current: '',
        temperature_on_rec_center_min: '',
        temperature_on_rec_center_max: '',
        temperature_on_rec_center_current: '',
        updated_at: '',
        //  Patient field
        birth_date_samples: '',
      },
    };
  },
  computed: {
    participants() {
      if (this.showSimpleOrAll) {
        return this.participantsData;
      }
      return this.participantsData.map((x) => ({
        id: x.id,
        cpf: x.cpf,
        actions: x.actions,
      }));
    },
    participantsLabels() {
      if (this.showSimpleOrAll) { return this.participantsFields; }
      return [
        'id', 'cpf', 'biologicalSampleType', 'actions',
      ];
    },
  },
  methods: {
    cleanError() {
      setTimeout(() => {
        this.error = false;
        this.errorMessage = '';
      }, 15000);
    },
    cleanSuccess() {
      setTimeout(() => {
        this.success = false;
        this.successMessage = '';
      }, 8000);
    },
    filter(flag) {
      this.filterFlag = flag;

      switch (flag) {
        case 'with-samples':
          this.participantsData = this.participantsData.filter((x) => x.samples.length > 0);
          break;
        case 'no-samples':
          this.participantsData = this.participantsData.filter((x) => x.samples.length == 0);
          break;
        default:
          this.getSamples();
          this.filterFlag = '';
      }
    },
    newSampleModalClick(participant) {
      if (this.participantInDetail == null) {
        this.setParticipant(participant);
      }
      this.hideModalParticipant();
      this.showModalSampleManualRegistration();
    },
    hideModalParticipant() {
      document.getElementById('closeModalParticipant').click();
    },
    showModalParticipant() {
      //  not use yet
    },
    hideModalSampleManualRegistration() {
      document.getElementsByName('SampleManualRegistrationName')[0].click();
    },
    showModalSampleManualRegistration() {
      document.getElementsByName('SampleManualRegistrationName')[0].click();
    },
    notifyError(message) {
      this.error = true;
      this.errorMessage = message;
    },
    notifySuccess(message) {
      this.success = true;
      this.successMessage = message;
    },
    updateRowTest() {
      this.participantsData.filter((x) => x.cpf === '1')[0].systemFlagPrint = true;
    },
    setParticipant(participant) {
      this.participantInDetail = participant;
      this.formData.patient_id = participant.id;
    },
    getSamples() {
      this.filterFlag = '';

      samplesFetchMyQuery()
        .then((response) => {
          if (response.error != null) {
            this.notifyError(response.error[0].message);
          } else {
            this.participantsData = response.data;
          }
        })
        .catch((error) => { this.notifyError(String(error)); })
        .finally(() => { this.cleanError(); });
    },
    getCenters() {
      recruitingCenterFetchMyQuery()
        .then((response) => {
          if (response.error != null) {
            this.notifyError(response.error[0].message);
          } else {
            const arrayCenterOptions = [];
            response.data.map(async (partner) => {
              await partner.unities.map(async (unit) => {
                arrayCenterOptions.push({
                  value: unit.id,
                  text: `${partner.name} - ${unit.name}`,
                  data: unit,
                });
              });
            });
            this.recruitingCenterOptions = arrayCenterOptions;
          }
        })
        .catch((error) => { this.notifyError(String(error)); })
        .finally(() => { this.cleanError(); });
    },
    transformDate(dateString) {
      const dateSplited = dateString.split(' ');
      const sDay = dateSplited[0].split('/');
      const formatted = `${[sDay[2], sDay[1], sDay[0]].join('-')}T${dateSplited[1]}:00`;
      return formatted;
    },
    transformDateOfDatepicker(date) {
      if (date != null) {
        if (date.toString().length == 16) return date;

        const day = (date.getDate() < 10) ? `0${date.getDate()}` : date.getDate();
        const month = ((date.getMonth() + 1) < 10) ? `0${(date.getMonth() + 1)}` : date.getMonth() + 1;
        const year = date.getFullYear();
        const hours = (date.getHours() < 10) ? `0${date.getHours()}` : date.getHours();
        const minutes = (date.getMinutes() < 10) ? `0${date.getMinutes()}` : date.getMinutes();

        const formatted = `${[year, month, day].join('-')}T${hours}:${minutes}`;
        return formatted;
      }
      return null;
    },
    onSubmitSampleManualRegistration(event) {
      event.preventDefault();
      const context = this;
      const data = context.formData;

      data.birth_date_samples = context.transformDateOfDatepicker(context.birth_date_samples);
      data.collected_at = context.transformDateOfDatepicker(context.collected_at);
      data.biological_sample_processed_at = context.transformDateOfDatepicker(context.biological_sample_processed_at);
      data.receipt_at = context.transformDateOfDatepicker(context.receipt_at);

      newSampleFetch(data)
        .then((response) => {
          if (response.error != null) {
            this.notifyError(response.error[0].message);
          } else {
            console.log('[+] success');
            this.notifySuccess('Amostra cadastrada com sucesso.');
            this.getSamples();
            this.hideModalSampleManualRegistration();
          }
        })
        .catch((error) => { this.notifyError(String(error)); })
        .finally(() => { this.cleanError(); this.cleanSuccess(); });
    },
    etiqueta() {
      console.log('Função indisponível.');
    },
    async logout() {
      await this.$store.dispatch('logout');
      await this.$router.push({ name: 'LoginPage' });
    },
    pageMounted() {
      const context = this;

      context.receipt_at = context.transformDateOfDatepicker(new Date());

      if (this.loading === true) {
        this.loading = false;
      }
    },
  },
  async mounted() {
    try {
      this.getSamples();
      this.getCenters();
      this.pageMounted();
    } catch (e) {
      console.error(e);
    }
  },
};
</script>

<style scoped>
.btn-line {
  margin-right: 1rem;
}

.small-3 {
  font-size: 14px;
}

.small-4 {
  font-size: 12px;
}

.page-default {
  margin: 72px auto;
  text-align: left;
}

.progress-bar {
  color: #ffffff;
  background: #c23d78;
}

.form-header {
  display: flex;
  align-items: center;
  font-size: 2rem;
}

.form-nav-btns {
  display: flex;
  justify-content: space-between;
}

.form-pagination-button {
  min-width: 145px;
  color: #ffffff;
  background: #c23d78;
  border-color: #c23d78;
  font-size: 16px;
  line-height: 42px;
}

.tr {
  height: 48px;
}

@media (min-width: 768px) {
  .form-nav-btns {
    padding: 0 38px;
  }

  .form-pagination-button {
    min-width: 180px;
    font-size: 16px;
    line-height: 46px;
  }

  .required {
    color: #c23d78;
  }
}
</style>
