import { createStore } from 'vuex';

// MODULES
import auth from './auth/auth';

const store = createStore({
  modules: {
    auth,
  },
});

export default store;
