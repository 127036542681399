<!-- TODO

  Adicionar mascaras e validações
  https://getbootstrap.com/docs/5.0/forms/validation/
  Adicionar icones no layout

-->

<template>
  <div class="hero-page login-page" :style="{
    height: '100vh',
    backgroundImage: `linear-gradient( rgba(255, 255, 255, 1.45), rgba(255, 255, 255, 0.45) ), 
                      url(${require('@/../public/images/background-color.png')})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'norepeat',
  }">

    <div class="col justify-content-center">
      <div class="col">
        <div class="wrap d-md-flex">
          <div class="login-wrap p-4 p-md-5" style="margin: 3rem auto; min-width: 600px;">
            <div class="d-flex">
              <div class="w-100" style="margin-bottom: 24px;">
                <img src="@/assets/logo_gen-t_brasil.png" alt="Logo Projeto Gen-t" width="183" fluid />
              </div>
              <div class="w-100">
              </div>
            </div>
            <form id="login-form" @submit.stop.prevent class="login-area">

              <div class="form-group mb-3" v-if="feedbackSubmitMessage.length > 0">
                <div class="alert alert-danger" role="alert">Error: {{feedbackSubmitMessage}}</div>
              </div>

              <div class="form-group mb-3">
                <label class="label mt-2 login" for="projetos">Login</label>
                <input type="text" class="form-control" placeholder="Username" v-model="loginData.username" id="username" inputmode="text" />
              </div>

              <div class="form-group mb-3">
                <label class="label mt-2" for="password">Senha</label>
                <input type="text" v-model="loginData.password" id="password" class="form-control" placeholder="Password" trim />
              </div>

              <br />

              <div class="form-group">
                <button type="submit" class="form-control btn btn-primary rounded submit px-3"
                        :disabled="loading" @click="onSubmit">
                        {{ btnMessage }}
                </button>
                <hr />
                <button type="submit" class="form-control btn btn-primary rounded px-3" 
                        @click="onViaToken" disabled>
                  Entrar via token
                </button>
              </div>

              <div class="form-group d-md-flex">
                <div class="w-50 text-left">
                  <!-- <label class="checkbox-wrap checkbox-primary mb-0">Remember Me
                    <input type="checkbox" checked="">
                    <span class="checkmark"></span>
                  </label> -->
                </div>
                <div class="w-50 text-md-right">
                  <!-- <a href="#">Esqueceu a senha?</a> -->
                </div>
              </div>
            </form>
            <!-- <p class="text-center">Not a member? <a data-toggle="tab" href="#signup">Sign Up</a></p> -->
          </div>
        </div>
      </div>
    </div>

    <div class="modal" id="feedback-submit" centered title="Gen-t" hide-footer>
      <p class="my-4 text-center">{{ feedbackSubmitMessage }}</p>
      <button class="mt-3" block @click="$bvModal.hide('feedback-submit')">ok</button>
    </div>

    <!-- TODO: Passar pro novo modal <div class="modal" id="login-via-token" centered title="Login via token - confira seu email" hide-footer>
      <validation-observer ref="form">
        <p class="my-4 text-center">Código enviado para sua caixa de entrada.</p>

        <label for="token" class="mt-2">Código</label>
        <input class="form-control code-sequential" type="text" v-model="code[0]" id="code0" trim />
        <input class="form-control code-sequential" type="text" v-model="code[1]" id="code1" trim />
        <input class="form-control code-sequential" type="text" v-model="code[2]" id="code2" trim />
        <input class="form-control code-sequential" type="text" v-model="code[3]" id="code3" trim />
        <button class="mt-3" block @click="onTokenValidate">Validar token</button>
      </validation-observer>
    </div> -->

    <!-- TODO: Passar pro novo modal <div class="modal" id="feedback-token" centered title="Gen-t" hide-footer>
      <p class="my-4 text-center">{{ feedbackTokenMessage }}</p>
      <button class="mt-3" block @click="$bvModal.hide('feedback-token')">ok</button>
    </div> -->

    <div class="modal fade" ref="modalFeedbackSubmit" id="modal-feedback-submit" tabindex="-1"
      data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Mensagem</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="alert alert-danger" role="alert">Error: {{feedbackSubmitMessage}}</div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="closeFeedback()">Fechar</button>
          </div>
        </div>
      </div>
    </div>

    <button id="modalFeedbackSubmit" type="button" class="btn btn-secondary visually-hidden" 
            data-bs-toggle="modal" data-bs-target="#modal-feedback-submit">
            Feedback Call
    </button>
  </div>
</template>

<script>
import axios from 'axios';
import router from '@/router';
import Config from '@/config/config';
import formatters from '@/../mixins/formatter.js';
import AuthService from '@/services/auth';

const BaseAuthUrl = Config.AUTH_API_URL;
const LoginURL = '/user/login';
const LoginViaTokenURL = '/user/login/token';
//  const TokenValidateURL = '/token/validate';

export default {
  mixins: [formatters],
  data() {
    return {
      loading: true,
      btnMessage: 'Carregando ',
      feedbackSubmitMessage: '',
      feedbackTokenMessage: '',
      token: '',
      code: ['-', '-', '-', '-'],
      generatedCodeToCompare: '',
      generatedCodeToValidate: '',
      loginData: {
        username: '',
        password: '',
      },
      options: {
        projetos: [
          { text: 'Por favor selecione', value: null },
        ],
        centros: [
          { text: 'Por favor selecione', value: null },
        ],
      },
      finTriggers: {
        projetos: false,
        centros: false,
      },
    };
  },
  methods: {
    closeFeedback() {
      this.feedbackSubmitMessage = '';
    },
    pageMounted() {
      if (this.loading === true) {
        this.loading = false;
        this.btnMessage = 'Entrar';
      }
    },
    async onViaToken(event) {
      event.preventDefault();
      this.loading = true;

      const USERNAME = this.loginData.username;
      if (USERNAME.length == 0) {
        this.feedbackTokenMessage = 'Preencha o email do login';
        this.$bvModal.show('feedback-token');
        this.loading = false;
        return;
      }

      const data = {
        username: USERNAME,
        template_id: 2,
      };

      axios.post(`${BaseAuthUrl}${LoginViaTokenURL}`, data, {
        headers: { 'Content-Type': 'application/json' },
      })
        .then((response) => {
          if (response.status == 200 && success) {
            this.$bvModal.show('login-via-token');
            this.generatedCodeToCompare = response.data.code;
          } else {
            this.feedbackTokenMessage = 'Erro no envio do token, tente novamente e caso persistir, entre em coantato.';
            // this.$bvModal.show('feedback-token');
          }
        })
        .catch((error) => {
          this.feedbackTokenMessage = error.message;
          // this.$bvModal.show('feedback-token');
        }).finally(() => {
          this.loading = false;
        });
    },
    async onTokenValidate(event) {
      event.preventDefault();
      this.loading = true;

      const TOKEN = `${this.code[0]}${this.code[1]}${this.code[2]}${this.code[3]}`;
      if (TOKEN.length == 0) {
        this.feedbackTokenMessage = 'Token inválido.';
        // this.$bvModal.show('feedback-token');
        this.loading = false;
      } else if (TOKEN !== this.generatedCodeToCompare) {
        this.feedbackTokenMessage = 'Códigos não correnpondentes.';
        // this.$bvModal.show('feedback-token');
        this.loading = false;
      } else {
        router.push('/samples');
        this.loading = false;
      }
    },
    async onSubmit(event) {
      event.preventDefault();

      const context = this;

      this.loading = true;
      this.btnMessage = 'Autenticando ';

      const data = {
        username: context.loginData.username,
        password: context.loginData.password,
        system: Config.SYSTEM_NAME,
      };

      axios.post(`${BaseAuthUrl}${LoginURL}`, data, {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          if (response.status == 200 && response.data.success) {
            const DATA = response.data.data;
            const DATA_STRINGIFY = JSON.stringify(DATA);
            AuthService.toSession(DATA_STRINGIFY);
            this.goNext();
          }
        })
        .catch((error) => {
          context.feedbackSubmitMessage = error.message;
          document.getElementById('modalFeedbackSubmit').click();
        }).then(() => {
          context.btnMessage = 'Entrar';
          context.loading = false;
        });
    },
    goNext() {
      // TODO: redirect to next page
      this.$router.push({ name: 'SamplesPage' });
    },
  },
  async mounted() {
    try {
      this.pageMounted();
    } catch (e) {
      console.error(e);
    }
  },
};
</script>

<style scoped>
.login-area {
  padding: 2rem;
  background-color: rgb(255, 255, 255);
  color: rgba(58, 53, 65, 0.87);
  border-radius: 6px;
  box-shadow: rgb(58 53 65 / 10%) 0px 2px 10px 0px;
}

.obs {
  font-size: 0.875rem;
  color: rgba(58, 53, 65, 0.68);
  font-weight: 800;
}

.code-sequential {
  /* TODO style inline  */
}
</style>
