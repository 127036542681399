/* eslint-disable no-return-await */
import Config from '@/config/config';
import AuthService from '@/services/auth';

const ApiUrl = Config.API_URL;
const GraphQLSufix = '/v1/graphql';

async function GetUserToken() {
  const userInSession = await AuthService.getAuthData();
  return userInSession.Token;
}

async function fetchGraphQL(operationsDoc, operationName, variables) {
  const TokenData = await GetUserToken();
  const result = await fetch(`${ApiUrl}${GraphQLSufix}`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${TokenData}`,
      'Content-Type': 'application/json',
      'x-hasura-role': 'admin',
    }),
    body: JSON.stringify({
      query: operationsDoc,
      variables,
      operationName,
    }),
  });

  return await result.json();
}

const query = `
  query Partners {
    partner {
      id
      name
      description
      unities(where: { active: { _eq: true }}) {
        id
        name
        active
      }
    }
  }
`;

function fetchMyQuery() {
  return fetchGraphQL(query, 'Partners', {});
}

async function startFetchMyQuery() {
  const { errors, data } = await fetchMyQuery();

  if (errors) return { error: errors };
  if (data.partner.length === 0) return { error: [{ message: 'Listagem vazia.' }] };

  return { data: data.partner };
}

export default startFetchMyQuery;
