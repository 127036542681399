import { createRouter, createWebHistory } from 'vue-router';

import LoginPage from '../views/login.vue';
import SamplesPage from '../views/samples.vue';
import AuthService from '../services/auth';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { name: 'MainPage', path: '/', component: LoginPage },
    { 
      name: 'LoginPage', path: '/auth', component: LoginPage, meta: { needAuth: false },
    },
    { 
      name: 'LoginPage', path: '/login', component: LoginPage, meta: { needAuth: false },
    },
    { 
      name: 'SamplesPage', path: '/samples', component: SamplesPage, meta: { needAuth: true },
    },
    { 
      name: 'NotFoundPage', path: '/:notFound(.*)', component: LoginPage,
    },
  ],
});

router.beforeEach((to, _, next) => {
  // testar redirecionamento por enquanto
  // store.commit('setAuthToken', 'test');
  const authData = AuthService.getAuthData();
  // isn't authenticated
  if (to.meta.needAuth && !authData?.Token) {
    next('/'); // TODO: criar rota de auth como no whats-integrator
    // is authenticated ??
  } else if (to.meta.needAuth && authData?.Token) {
    next();
    // always redirect samples if authenticated
  } else if (authData?.Token) {
    next('/samples');
  } else {
    next();
  }
});

export default router;
