import axios, { AxiosInstance } from 'axios';
import Config from '../config/config';
import store from '../store';

export interface LoginResponseData {
  Name: string
  Blocked: boolean
  Permissions: Array<string>
  System: string
  Token: string
  UID: string
}
interface LoginResponse {
  data: LoginResponseData,
  success: boolean
}

class AuthRemote {
  private api: AxiosInstance;
  
  constructor() {
    this.api = axios.create({
      baseURL: Config.AUTH_API_URL,
    });
  }

  async login(username: string, password: string) {
    try {
      const res = await this.api.post('/user/login', {
        username,
        password,
        system: Config.SYSTEM_ID,
      });

      const response: LoginResponse = res.data;
      return response;
    } catch (error) {
      throw new Error(`AuthRemote.login - error: ${error}`);
    }
  }
}

export default new AuthRemote();
