import { AuthData } from '../../services/auth';
import config from '../../config/config';

const emptyState: AuthData = {
  Blocked: false,
  Name: '',
  Permissions: [],
  System: '',
  Token: '',
  UID: '',
};

export default {
  state() {
    return {
      authData: emptyState,
    };
  },
  getters: {
    authData: (state: any) => state.authData,
  },
  mutations: {
    setAuthData: (state: any, data: any) => {
      state.authData = data;
    },
    resetAuthData: (state: any) => {
      state.authData = emptyState;
    },
  },
  actions: {
    logout: (context: any) => {
      context.commit('resetAuthData');
      sessionStorage.removeItem(config.SESSION);
    },
  },
};
