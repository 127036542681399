/* eslint-disable class-methods-use-this */
import AuthRemote, { LoginResponseData } from '../remotes/auth';
import CryptService from './crypt';
import store from '../store';
import config from '../config/config';

enum LoginResult {
  success = 'success',
  unauthorized = 'unauthorized',
  blocked = 'blocked'
}

const emptyAuthData: AuthData = {
  Name: '',
  Blocked: false,
  Permissions: [],
  System: '',
  Token: '',
  UID: '',
};

export type AuthData = LoginResponseData

class AuthService {
  private session = config.SESSION;
  
  constructor() {
    this.load();
  }

  async login(username: string, password: string): Promise<LoginResult> {
    try {
      const data = await AuthRemote.login(username, password);

      if (!data.success) {
        return LoginResult.unauthorized;
      }

      if (data.data.Blocked) {
        return LoginResult.blocked;
      }

      this.toStore(data.data);
      this.toSession(data.data);
      return LoginResult.success;
    } catch (error) {
      throw new Error(`AuthService.login - error ${error}`);
    }
  }

  logout() {
    this.clearSession();
    store.commit('resetAuthData');
  }

  getAuthData() {
    return this.fromSession();
  }
  
  private toStore(data: AuthData) {
    store.commit('setAuthData', data);
  }

  private toSession(data: AuthData) {
    const str = CryptService.encrypt(JSON.stringify(data));
    sessionStorage.setItem(this.session, str);
  }

  private fromSession(): AuthData {
    const str = sessionStorage.getItem(this.session) ?? '';
    if (str != '') {
      const data = JSON.parse(JSON.parse(CryptService.decrypt(str))); // TODO: fix Json.parse duplicate
      return data;
    }

    return emptyAuthData;
  }

  private clearSession() {
    sessionStorage.removeItem(this.session);
  }

  private load() {
    const data = this.fromSession();
    this.toStore(data);
  }
}

export default new AuthService();
