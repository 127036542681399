import CryptoJS from 'crypto-js';
import config from '../config/config';

class CryptService {
  encrypt(text: string) {
    const parsed = CryptoJS.enc.Utf8.parse(text);
    const encrypted = CryptoJS.AES.encrypt(parsed, this.key, this.config).toString();
    return encrypted;
  }

  decrypt(text: string) {
    return CryptoJS.AES.decrypt(text, this.key, this.config).toString(CryptoJS.enc.Utf8);
  }

  // eslint-disable-next-line class-methods-use-this
  private get key() {
    const str = config.AES_KEY.split(' ').reverse().join(' ');
    return CryptoJS.enc.Utf8.parse(str).toString();
  }

  private get config() {
    return {
      iv: CryptoJS.enc.Utf8.parse(this.key),
      mode: CryptoJS.mode.CBC,
    };
  }
}

export default new CryptService();
