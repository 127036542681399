<!-- <template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style lang="scss">
  @import '@/assets/styles/base.css';
</style> -->

<template>
  <router-view></router-view>
</template>
